// SalesReducers.js
import { SalesTypes } from "../action";

const initialState = {
  orders: {},
};

const SalesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SalesTypes.SalesData:
      return { ...state, data: action.payload };
    case SalesTypes.Sales_data:
      return { ...state, data_coil: action.payload };
    case SalesTypes.BookingviewData:
      return { ...state, bookingViewData: action.payload };
    case SalesTypes.BookingData:
      return { ...state, bookingData: action.payload };
    case SalesTypes.OrderviewData:
      return { ...state, orderViewData: action.payload };
    case SalesTypes.OrderData:
      return { ...state, orderData: action.payload };
    case SalesTypes.coil_assign:
      return { ...state, coilAssignData: action.payload };
    case SalesTypes.pipeorderdata:
      return { ...state, pipeOrderData: action.payload };
    case SalesTypes.PipeAssignviewData:
      return { ...state, pipeAssignViewData: action.payload };
    case SalesTypes.CoilAssignviewData:
      return { ...state, coilAssignViewData: action.payload };
    case SalesTypes.RetantionData:
      return { ...state, retentionData: action.payload };
    case SalesTypes.RetantData:
      return { ...state, retantData: action.payload };
    case SalesTypes.SalesRetantionData:
      return { ...state, salesretantiondata: action.payload };
    case SalesTypes.DispatchSummary:
      return { ...state, dispatchSummary: action.payload };
    case SalesTypes.pipe_assign:
      return { ...state, pipeAssign: action.payload };
    case SalesTypes.summarysalesTeam:
      return { ...state, summarySalesTeam: action.payload };
    case SalesTypes.DealerIntendDa:
      return { ...state, DealerIntendData: action.payload };
    default:
      return state;
  }
};

export default SalesReducer;
