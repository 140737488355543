import React, { useState } from "react";
import { Form, Input, InputNumber, Button, Upload, Row, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Select from "react-select";
import { toast } from "react-toastify"; // Ensure you have react-toastify installed
import { api } from "../../../services/api";

const DealerForm = ({ onClose }) => {
  const [form] = Form.useForm();

  const [imageBase64, setImageBase64] = useState(null);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleImageUpload = async (file) => {
    const base64 = await getBase64(file);
    setImageBase64(base64);
    return false; // Prevent default upload
  };

  const onFinish = (values) => {
    // Prepare form data
    const decisionMakerValue = values.decisionMaker?.value;
    const usageTypeValue = values.usageType?.value;
    const quantityrValue = values.quantity?.value;
    const requirementTypeValue = values.requirementType?.value;
    const shopTypeValue = values.shopType?.value;
    const form_data = {
      ...values,
      image: imageBase64, // Add Base64 image to the form data
      decisionMaker: decisionMakerValue,
      shopType: shopTypeValue,
      usageType: usageTypeValue,
      requirementType: requirementTypeValue,
      quantity: quantityrValue,
    };

    console.log("Form Values:", form_data);
    api({
      api: "/api/dealerintend/",
      method: "post",
      body: {
        form: form_data,
        post: 1,
      },
    })
      .then(() => {
        // Show success notification
        toast.success("Success", { autoClose: 2000 });

        // Reload the page or perform any other action
        window.location.reload();
      })
      .catch(() => {
        // Show failure notification
        toast.error("Failed", { autoClose: 2000 });
      });
  };

  const options = {
    shopType: [
      { value: "Owned", label: "Owned" },
      { value: "Rented", label: "Rented" },
    ],
    decisionMaker: [
      { value: "Owned", label: "Owned" },
      { value: "Son", label: "Son" },
      { value: "Father", label: "Father" },
      { value: "Partnership", label: "Partnership" },
      { value: "Brothers", label: "Brothers" },
      { value: "Other", label: "Other" },
    ],
    usageType: [
      { value: "Project", label: "Project" },
      { value: "End Use", label: "End Use" },
      { value: "Trading & Reselling", label: "Trading & Reselling" },
    ],
    quantity: [
      { value: "5MT", label: "5MT" },
      { value: "10MT", label: "10MT" },
      { value: "15MT", label: "15MT" },
      { value: "20MT", label: "20MT" },
      { value: "25MT", label: "25MT" },
      { value: "Above", label: "Above" },
    ],
    requirementType: [
      { value: "On Time", label: "On Time" },
      { value: "Recurring", label: "Recurring" },
    ],
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      style={{
        background: "#fff",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      }}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="firmName"
            label="Firm Name"
            rules={[{ required: true, message: "Please enter the firm name" }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="ownerName"
            label="Owner Name"
            rules={[
              { required: true, message: "Please enter the owner name" },
            ]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="address"
            label="Address"
            rules={[{ required: true, message: "Please enter the address" }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="phone"
            label="Phone No."
            rules={[
              {
                required: true,
                pattern: /^\d{10}$/,
                message: "Enter a valid 10-digit phone number",
              },
            ]}>
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="shopType"
            label="Shop Type"
            rules={[{ required: true }]}>
            <Select options={options.shopType} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="numEmployees" label="No. of Employees Present">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="shopSize" label="Shop Size (Sq. Ft)">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="decisionMaker" label="Decision Maker">
            <Select
              options={options.decisionMaker}
              onChange={(selectedOption) => {
                if (selectedOption.value === "Other") {
                  form.setFieldsValue({ otherDecisionMaker: "" });
                } else {
                  form.setFieldsValue({ otherDecisionMaker: undefined });
                }
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      {form.getFieldValue("decisionMaker")?.value === "Other" && (
        <Row>
          <Col span={24}>
            <Form.Item name="otherDecisionMaker" label="Specify Decision Maker">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="averageConsumption304"
            label="Average Monthly Consumption (304)">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="averageConsumption202"
            label="Average Monthly Consumption (202)">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="currentRate"
            label="Current Purchasing Rate of JT/304">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="usageType" label="What is the type of uses?">
            <Select options={options.usageType} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="gradePercent"
            label="What is the Grade of Percent (%) pipe you require?">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="quantity"
            label="What is the quantity of tube or pipe you require?">
            <Select options={options.quantity} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="deliveryDetails"
            label="What is the delivery address and preferred delivery date?">
            <Input rows={3} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="dimensions"
            label="What is the size and dimensions of the tube or pipe you require?">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="currentBrands" label="Current Brands Dealing In?">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="requirementType" label="Type of Requirement?">
            <Select options={options.requirementType} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item
            name="image"
            label="Upload Image"
            valuePropName="fileList"
            getValueFromEvent={(e) => e?.fileList}>
            <Upload
              beforeUpload={handleImageUpload}
              listType="picture"
              maxCount={1}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DealerForm;
