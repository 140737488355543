import GenrateQuery from "../../components/Common/generatequery";
import { api } from "../../services/api";
import { SalesTypes } from "../action";

// Helper function for API calls
const fetchApi = async (endpoint, dispatch, type) => {
  try {
    const data = await api({ api: endpoint });
    dispatch({ type, payload: data });
  } catch (error) {
    console.error(`Error fetching data: ${error.message}`);
  }
};

// Action Creators
const endpoint_SalesData = "/api/coil_assign/";
export const GetSalesData = (request) => async (dispatch) => {
  const url =
    endpoint_SalesData +
    GenrateQuery({
      page: request.page,
      size: request.size,
      filter: request.filter,
    });
  await fetchApi(url, dispatch, SalesTypes.Sales_data);
};

const endpoint_PipeData = "/api/pipeorder/";
export const GetPipeData = (request) => async (dispatch) => {
  const url =
    endpoint_PipeData +
    GenrateQuery({
      page: request.page,
      size: request.size,
      filter: request.filter,
    });
  await fetchApi(url, dispatch, SalesTypes.pipeorderdata);
};

const endpoint_OrderSalesData = "/api/order/";
export const GetOrderSalesData = (request) => async (dispatch) => {
  const url =
    endpoint_OrderSalesData +
    GenrateQuery({
      page: request.page,
      size: request.size,
      filter: request.filter,
    });
  await fetchApi(url, dispatch, SalesTypes.SalesData);
};

const endpoint_BookingData = "/api/booking/";
export const GetBookingData = (request) => async (dispatch) => {
  const url =
    endpoint_BookingData +
    GenrateQuery({
      page: request.page,
      size: request.size,
      filter: request.filter,
    });
  await fetchApi(url, dispatch, SalesTypes.BookingData);
};

const endpoint_Retantion = "/api/rentation/";
export const GetRetantionData = (request) => async (dispatch) => {
  const url =
    endpoint_Retantion +
    GenrateQuery({
      page: request.page,
      size: request.size,
      filter: request.filter,
    });
  await fetchApi(url, dispatch, SalesTypes.RetantionData);
};

const endpoint_SalesRetantion = "/api/commonviewset/";
export const GetSalesRetantionData = (request) => async (dispatch) => {
  const url =
    endpoint_SalesRetantion +
    GenrateQuery({
      page: request.page,
      size: request.size,
      filter: request.filter,
    });
  await fetchApi(url, dispatch, SalesTypes.SalesRetantionData);
};

const endpoint_SummarySalesteam = "/api/salesteam/";
export const GetSummarySalesTeamData = (request) => async (dispatch) => {
  const url =
    endpoint_SummarySalesteam +
    GenrateQuery({
      page: request.page,
      size: request.size,
      filter: request.filter,
    });
  await fetchApi(url, dispatch, SalesTypes.summarysalesTeam);
};

const endpoint_Retant = "/api/rentat/";
export const GetRetantData = (request) => async (dispatch) => {
  const url =
    endpoint_Retant +
    GenrateQuery({
      page: request.page,
      size: request.size,
      filter: request.filter,
    });
  await fetchApi(url, dispatch, SalesTypes.RetantData);
};

const endpoint_DealerIntend = "/api/dealerintend/";
export const GetDealerIntendtData = (request) => async (dispatch) => {
  const url =
    endpoint_DealerIntend +
    GenrateQuery({
      page: request.page,
      size: request.size,
      filter: request.filter,
    });
  await fetchApi(url, dispatch, SalesTypes.DealerIntendDa);
};

export const GetBookingviewData = (endpoint) => async (dispatch) => {
  await fetchApi(endpoint, dispatch, SalesTypes.BookingviewData);
};

export const GetOrderviewData = (endpoint) => async (dispatch) => {
  await fetchApi(endpoint, dispatch, SalesTypes.OrderviewData);
};

export const GetOrderData = (endpoint) => async (dispatch) => {
  await fetchApi(endpoint, dispatch, SalesTypes.OrderData);
};

export const GetCoilAssignData = (endpoint) => async (dispatch) => {
  await fetchApi(endpoint, dispatch, SalesTypes.coil_assign);
};

export const GetPipeAssignData = (endpoint) => async (dispatch) => {
  await fetchApi(endpoint, dispatch, SalesTypes.pipe_assign);
};

export const GetPipeAssignviewData = (endpoint) => async (dispatch) => {
  await fetchApi(endpoint, dispatch, SalesTypes.PipeAssignviewData);
};

export const GetCoilAssignviewData = (endpoint) => async (dispatch) => {
  await fetchApi(endpoint, dispatch, SalesTypes.CoilAssignviewData);
};
