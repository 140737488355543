import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Form, InputNumber, Select, Space } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetPipeAssignData } from "../../../redux/actions/salesAction";

const { Option } = Select;

const initialFormValues = {
  stock: null,
  size: null,
  thickness: null,
  brand: null,
  stamp: null,
  status: null,
  shape: null,
  weight: null,
  rate: null,
  length: null,
  discount: null,
  bundle: null,
  pipe: null,
  actualWeight: null,
};

const PipeModal_form = () => {
  const coilAssignT = useSelector((state) => state.SalesData.Pipeassign);
  const [isModalVisible, setIsModalVisible] = useState(false);
  let { id } = useParams();
  const [refresh, setRef] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [thicknesses, setThicknesses] = useState([]);
  const [stamps, setStamps] = useState([]);

  useEffect(() => {
    dispatch(GetPipeAssignData("/sales/pipeEassign/" + id));
  }, [refresh, id, dispatch]);

  useEffect(() => {
    if (coilAssignT) {
      // Process coilAssignT to extract and set options for stocks, sizes, etc.
      setStocks(coilAssignT.stocks);
      setSizes(coilAssignT.sizes);
      setThicknesses(coilAssignT.thicknesses);
      setStamps(coilAssignT.stamps);
    }
  }, [coilAssignT]);

  const handleAdd = () => {
    setDataSource([...dataSource, { ...initialFormValues }]);
  };

  const handleDelete = (index) => {
    const newData = [...dataSource];
    newData.splice(index, 1);
    setDataSource(newData);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        console.log("Form Data:", values);
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleStockChange = (value, index) => {
    const selectedStock = stocks.find((stock) => stock.value === value);
    // Fetch related sizes for the selected stock
    const relatedSizes = selectedStock ? selectedStock.sizes : [];
    form.setFieldsValue({
      [`items[${index}].size`]: null,
      [`items[${index}].thickness`]: null,
      [`items[${index}].stamp`]: null,
    });
    setSizes(relatedSizes);
  };

  const handleSizeChange = (value, index) => {
    const selectedSize = sizes.find((size) => size.value === value);
    // Fetch related thicknesses for the selected size
    const relatedThicknesses = selectedSize ? selectedSize.thicknesses : [];
    form.setFieldsValue({
      [`items[${index}].thickness`]: null,
      [`items[${index}].stamp`]: null,
    });
    setThicknesses(relatedThicknesses);
  };

  const handleThicknessChange = (value, index) => {
    const selectedThickness = thicknesses.find(
      (thickness) => thickness.value === value
    );
    // Fetch related stamps for the selected thickness
    const relatedStamps = selectedThickness ? selectedThickness.stamps : [];
    form.setFieldsValue({
      [`items[${index}].stamp`]: null,
    });
    setStamps(relatedStamps);
  };

  const columns = [
    {
      title: "Stock",
      dataIndex: "stock",
      width: 150,
      render: (_, record, index) => (
        <Form.Item name={["items", index, "stock"]} initialValue={record.stock}>
          <Select
            options={stocks} // Use the stocks data
            placeholder="Select Stock"
            onChange={(value) => handleStockChange(value, index)}
          />
        </Form.Item>
      ),
    },
    {
      title: "Size",
      dataIndex: "size",
      width: 150,
      render: (_, record, index) => (
        <Form.Item name={["items", index, "size"]} initialValue={record.size}>
          <Select
            options={sizes} // Use the sizes data
            placeholder="Select Size"
            onChange={(value) => handleSizeChange(value, index)}
          />
        </Form.Item>
      ),
    },
    {
      title: "Thickness",
      dataIndex: "thickness",
      width: 150,
      render: (_, record, index) => (
        <Form.Item
          name={["items", index, "thickness"]}
          initialValue={record.thickness}>
          <Select
            options={thicknesses} // Use the thicknesses data
            placeholder="Select Thickness"
            onChange={(value) => handleThicknessChange(value, index)}
          />
        </Form.Item>
      ),
    },
    {
      title: "Stamp",
      dataIndex: "stamp",
      width: 150,
      render: (_, record, index) => (
        <Form.Item name={["items", index, "stamp"]} initialValue={record.stamp}>
          <Select
            options={stamps} // Use the stamps data
            placeholder="Select Stamp"
          />
        </Form.Item>
      ),
    },
    {
      title: "Rate",
      dataIndex: "rate",
      render: (_, record, index) => (
        <Form.Item name={["items", index, "rate"]} initialValue={record.rate}>
          <InputNumber placeholder="Rate" />
        </Form.Item>
      ),
    },
    {
      title: "Length",
      dataIndex: "length",
      render: (_, record, index) => (
        <Form.Item
          name={["items", index, "length"]}
          initialValue={record.length}>
          <InputNumber placeholder="Length" />
        </Form.Item>
      ),
    },
    {
      title: "Discount",
      dataIndex: "discount",
      render: (_, record, index) => (
        <Form.Item
          name={["items", index, "discount"]}
          initialValue={record.discount}>
          <InputNumber placeholder="Discount" />
        </Form.Item>
      ),
    },
    {
      title: "Bundle",
      dataIndex: "bundle",
      width: 100,
      render: (_, record, index) => (
        <Form.Item
          name={["items", index, "bundle"]}
          initialValue={record.bundle}>
          <InputNumber placeholder="Bundle" />
        </Form.Item>
      ),
    },
    {
      title: "Pipe",
      dataIndex: "pipe",
      width: 100,
      render: (_, record, index) => (
        <Form.Item name={["items", index, "pipe"]} initialValue={record.pipe}>
          <InputNumber placeholder="Pipe" />
        </Form.Item>
      ),
    },
    {
      title: "Actual Weight",
      dataIndex: "actualWeight",
      width: 100,
      render: (_, record, index) => (
        <Form.Item
          name={["items", index, "actualWeight"]}
          initialValue={record.actualWeight}>
          <InputNumber placeholder="Weight" />
        </Form.Item>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record, index) => (
        <Space>
          <Button
            type="danger"
            onClick={() => handleDelete(index)}
            icon={<MinusCircleOutlined />}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Button type="primary" onClick={() => setIsModalVisible(true)}>
        Open Modal Form
      </Button>
      <Modal
        title="Add Items"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1400}>
        <Form form={form} name="dynamic_form_nest_item" autoComplete="off">
          <Table
            dataSource={dataSource}
            columns={columns}
            rowKey="key"
            pagination={false}
            size="small"
            footer={() => (
              <Button
                type="dashed"
                onClick={handleAdd}
                block
                icon={<PlusOutlined />}>
                Add Row
              </Button>
            )}
          />
        </Form>
      </Modal>
    </>
  );
};

export default PipeModal_form;
