import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table, Input, Row, Col, message } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { api } from "../../../services/api";
import moment from "moment";

const IssueItemFormModal = ({ visible, onClose, onSuccess }) => {
  const [barcode, setBarcode] = useState("");
  const [rows, setRows] = useState([
    { key: Date.now(), quantity: 0, balance: 0, initialBalance: 0 },
  ]);
  const [filteredFinalStatusOptions, setFilteredFinalStatusOptions] = useState(
    []
  );
  const [form] = Form.useForm();
  const [stockItems, setStockItems] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);
  const [rateOptions, setRateOptions] = useState([]);
  const [rackOptions, setRackOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [racklist, setRacklist] = useState([]);
  useEffect(() => {
    api({ api: "/storeitem/stocklistitem/" }).then((data) => {
      setStockItems(data);
      // Extract unique names for item options
      const uniqueNames = Array.from(new Set(data.map((item) => item.name)));
      setItemOptions(uniqueNames.map((name) => ({ value: name, label: name })));
    });
    api({ api: "/storeitem/racklisted/" }).then((data) => {
      setRacklist(data);
    });
  }, []);

  const handleAddRow = () => {
    setRows([
      ...rows,
      { key: Date.now(), quantity: 0, balance: 0, initialBalance: 0 },
    ]);
  };

  const handleDeleteRow = (key) => {
    const rowToDelete = rows.find((row) => row.key === key);
    if (rowToDelete) {
      // Restore the balance of the item to its initial value before deletion
      const updatedRows = rows
        .filter((row) => row.key !== key)
        .map((row) => {
          if (row.skuCode === rowToDelete.skuCode) {
            row.balance += rowToDelete.quantity;
          }
          return row;
        });
      setRows(updatedRows);
    }
  };

  useEffect(() => {
    // Handle barcode scanner input
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        // Process the scanned barcode
        const matchedItem = stockItems.find(
          (item) => item.code === barcode.trim() // Assuming 'code' is the barcode field
        );
        if (matchedItem) {
          const updatedRows = [...rows];
          const existingRowIndex = updatedRows.findIndex(
            (row) => row.skuCode === matchedItem.code
          );

          if (existingRowIndex !== -1) {
            // Update existing row
            updatedRows[existingRowIndex].quantity += 1; // Increment quantity
            updatedRows[existingRowIndex].balance =
              matchedItem.total_balance -
              updatedRows[existingRowIndex].quantity;
          } else {
            // Add a new row
            updatedRows.push({
              key: Date.now(),
              skuCode: matchedItem.code,
              itemName: matchedItem.name,
              description: matchedItem.description,
              unit: matchedItem.unit,
              initialBalance: matchedItem.total_balance,
              balance: matchedItem.total_balance - 1,
              quantity: 1,
            });
          }
          setRows(updatedRows);
        } else {
          message.error("Barcode not found!");
        }
        setBarcode(""); // Clear barcode buffer
      } else {
        // Append characters to barcode buffer
        setBarcode((prev) => prev + event.key);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [barcode, stockItems, rows]);

  const handleSubmit = (values) => {
    const transformedRows = rows.map((row) => ({
      skuCode: row.skuCode,
      itemName: row.itemName,
      description: row.description,
      unit: row.unit,
      rate: row.rate,
      rackNo: row.rackNo,
      quantity: row.quantity,
      balance: row.balance,
    }));

    // Prepare the data to be sent to the API
    const payload = {
      date: moment(selectedDate).format("DD/MM/YYYY"), // Format date as YYYY-MM-DD
      personName: values.personName,
      costCenterStatus: values.costCenterStatus.value,
      finalStatus: values.finalStatus.value,
      slipNo: values.slipNo,
      remarks: values.remarks,
      items: transformedRows, // Include the rows data in the payload
      post: 1,
    };

    console.log(payload);
    // Make the API call
    api({
      api: "/api/issue/",
      method: "post",
      body: payload, // Send the payload with form data
    })
      .then(() => {
        message.success("Form submitted successfully!");
        form.resetFields();

        // Clear the rows state
        setRows([
          { key: Date.now(), quantity: 0, balance: 0, initialBalance: 0 },
        ]);

        // Close the modal
        onClose();
        onSuccess();
      })
      .catch((error) => {
        message.error("Submission failed. Please try again.");
        console.error("Submission error:", error);
      });
  };

  const statusOptions = [
    { value: "Mill", label: "Mill" },
    { value: "Office", label: "Office" },
    { value: "Polish", label: "Polish" },
    { value: "Slitting", label: "Slitting" },
    { value: "Repair", label: "Repair" },
    { value: "House Hold", label: "House Hold" },
    { value: "Housekeeping", label: "Housekeeping" },
    { value: "Return of Goods", label: "Return of Goods" },
  ];

  const finalStatusOptions = [
    { parent: "Mill", value: "Mill-1", label: "Mill-1" },
    { parent: "Mill", value: "Mill-2", label: "Mill-2" },
    { parent: "Mill", value: "Mill-3", label: "Mill-3" },
    { parent: "Mill", value: "Mill-4", label: "Mill-4" },
    { parent: "Mill", value: "Mill-5", label: "Mill-5" },
    { parent: "Mill", value: "Mill-6", label: "Mill-6" },
    { parent: "Mill", value: "Mill-7", label: "Mill-7" },
    { parent: "Mill", value: "Mill-8", label: "Mill-8" },
    { parent: "Mill", value: "Mill-9", label: "Mill-9" },
    { parent: "Mill", value: "Mill-10", label: "Mill-10" },
    { parent: "Mill", value: "Mill-11", label: "Mill-11" },
    { parent: "Mill", value: "Mill-12", label: "Mill-12" },
    { parent: "Mill", value: "Mill-13", label: "Mill-13" },
    { parent: "Mill", value: "Mill-14", label: "Mill-14" },
    { parent: "Mill", value: "Mill-15", label: "Mill-15" },
    { parent: "Mill", value: "Mill-16", label: "Mill-16" },
    { parent: "Mill", value: "Mill-17", label: "Mill-17" },
    { parent: "Mill", value: "Mill-18", label: "Mill-18" },
    { parent: "Mill", value: "Project", label: "Project" },
    { parent: "Mill", value: "Plot No. 128", label: "Plot No. 128" },
    { parent: "Mill", value: "Mill-101", label: "Mill-101" },
    { parent: "Mill", value: "Mill-102", label: "Mill-102" },
    { parent: "Mill", value: "Electrical", label: "Electrical" },
    { parent: "Mill", value: "Mechanical", label: "Mechanical" },
    { parent: "Mill", value: "Repair", label: "Repair" },
    { parent: "Office", value: "Office", label: "Office" },
    { parent: "Office", value: "Housekeeping", label: "Housekeeping" },
    { parent: "Office", value: "Fixed Cost", label: "Fixed Cost" },
    {
      parent: "Office",
      value: "Returnable Device",
      label: "Returnable Device",
    },
    { parent: "Polish", value: "Mill-1", label: "Mill-1" },
    { parent: "Polish", value: "Mill-2", label: "Mill-2" },
    { parent: "Polish", value: "Mill-3", label: "Mill-3" },
    { parent: "Polish", value: "Mill-4", label: "Mill-4" },
    { parent: "Polish", value: "Mill-5", label: "Mill-5" },
    { parent: "Polish", value: "Mill-6", label: "Mill-6" },
    { parent: "Polish", value: "Mill-7", label: "Mill-7" },
    { parent: "Polish", value: "Project", label: "Project" },
    { parent: "Polish", value: "Project", label: "Project" },
    { parent: "Polish", value: "Electrical", label: "Electrical" },
    { parent: "Polish", value: "Mechanical", label: "Mechanical" },
    { parent: "Polish", value: "Repair", label: "Repair" },
    { parent: "Slitting", value: "Slitting", label: "Slitting" },
    { parent: "Slitting", value: "Project", label: "Project" },
    { parent: "Slitting", value: "Repair", label: "Repair" },
    { parent: "Slitting", value: "Electrical", label: "Electrical" },
    { parent: "Slitting", value: "Mechanical", label: "Mechanical" },
    { parent: "House Hold", value: "House Hold", label: "House Hold" },
    { parent: "Housekeeping", value: "Housekeeping", label: "Housekeeping" },
    {
      parent: "Return of Goods",
      value: "Return to Buyer",
      label: "Return to Buyer",
    },
    {
      parent: "Return of Goods",
      value: "Job",
      label: "Job",
    },
    {
      parent: "Return of Goods",
      value: "Repair",
      label: "Repair",
    },
    {
      parent: "Maintaince",
      value: "Electrical",
      label: "Electrical",
    },
    {
      parent: "Maintaince",
      value: "Slitting",
      label: "Slitting",
    },
    { parent: "Maintaince", value: "Mechanical", label: "Mechanical" },
    { parent: "Maintaince", value: "Operational", label: "Operational" },
    { parent: "Repair", value: "Repair", label: "Repair" },
    { parent: "Repair", value: "Mechanical", label: "Mechanical" },
    { parent: "Repair", value: "Electrical", label: "Electrical" },
  ];

  const handleStatusChange = (selectedOption) => {
    const filteredOptions = finalStatusOptions.filter(
      (option) => option.parent === selectedOption.value
    );
    setFilteredFinalStatusOptions(filteredOptions);
  };

  const handleSKUChange = (selectedOption, index) => {
    const selectedItem = stockItems.find(
      (item) => item.code === selectedOption.value
    );

    if (selectedItem) {
      // Fetch additional data based on SKU Code
      api({
        api: `/storeitem/stocklistitem/?code=${selectedOption.value}`,
      }).then((data) => {
        setRateOptions(data.rate.map((r) => ({ value: r, label: r })));
        setRackOptions(data.racks.map((r) => ({ value: r, label: r })));

        const updatedRows = rows.map((row, i) =>
          i === index
            ? {
                ...row,
                skuCode: selectedOption.value,
                itemName: selectedItem.name,
                description: selectedItem.description,
                unit: selectedItem.unit,
                initialBalance: selectedItem.total_balance,
                rateOptions: data.rate.map((r) => ({ value: r, label: r })),
                rackOptions: data.racks.map((r) => ({ value: r, label: r })),
              }
            : row
        );
        setRows(updatedRows);
      });
    }
  };

  const handleItemNameChange = (selectedOption, index) => {
    const descriptions = stockItems
      .filter((item) => item.name === selectedOption.value)
      .map((item) => ({ value: item.description, label: item.description }));

    const updatedRows = rows.map((row, i) =>
      i === index
        ? {
            ...row,
            itemName: selectedOption.value,
            descOptions: descriptions,
            description: null,
            skuCode: null,
            unit: null,
            initialBalance: null,
            rateOptions: [],
            rackOptions: [],
          }
        : row
    );
    setRows(updatedRows);
  };

  const handleDescriptionChange = (selectedOption, index) => {
    const selectedItem = stockItems.find(
      (item) =>
        item.name === rows[index].itemName &&
        item.description === selectedOption.value
    );

    if (selectedItem) {
      api({
        api: `/storeitem/stocklistitem/?code=${selectedItem.code}`,
      }).then((data) => {
        const updatedRows = rows.map((row, i) =>
          i === index
            ? {
                ...row,
                description: selectedOption.value,
                skuCode: selectedItem.code,
                unit: selectedItem.unit,
                initialBalance: selectedItem.total_balance,
                rateOptions: data.rate.map((r) => ({ value: r, label: r })),
                rackOptions: data.racks.map((r) => ({ value: r, label: r })),
              }
            : row
        );
        setRows(updatedRows);
        setRateOptions(data.rate.map((r) => ({ value: r, label: r })));
        setRackOptions(data.racks.map((r) => ({ value: r, label: r })));
      });
    }
  };

  const handleRateChange = (selectedOption, index) => {
    const updatedRows = rows.map((row, i) =>
      i === index
        ? {
            ...row,
            rate: selectedOption.value,
          }
        : row
    );
    setRows(updatedRows);
  };

  const handleRackChange = (selectedOption, index) => {
    const updatedRows = rows.map((row, i) =>
      i === index
        ? {
            ...row,
            rackNo: selectedOption.value,
          }
        : row
    );
    setRows(updatedRows);
  };

  const handleQuantityChange = (e, index) => {
    const inputValue = e.target.value;
    const quantity = parseFloat(inputValue) || 0; // Default to 0 if input is not a number
    const row = rows[index];
    const newBalance = row.initialBalance - quantity;

    if (newBalance < 0) {
      message.error("Quantity cannot exceed balance.");
      return;
    }

    const updatedRows = rows.map((r, i) =>
      i === index
        ? {
            ...r,
            quantity: quantity,
            balance: newBalance,
          }
        : r
    );
    setRows(updatedRows);
  };

  return (
    <Modal
      title="Issue Item Form"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={1400}>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Row gutter={8}>
          <Col span={4}>
            <Form.Item
              label="Date"
              name="date"
              initialValue={moment()} // Set initial value to today's date
              rules={[{ required: true, message: "Please select a date!" }]}>
              <DatePicker
                className="form-control"
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="dd/MM/yyyy"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Person Name"
              name="personName"
              rules={[
                { required: true, message: "Please input person name!" },
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Cost Center Status"
              name="costCenterStatus"
              rules={[
                {
                  required: true,
                  message: "Please select cost center status!",
                },
              ]}>
              <Select options={statusOptions} onChange={handleStatusChange} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Final Status"
              name="finalStatus"
              rules={[
                { required: true, message: "Please select final status!" },
              ]}>
              <Select options={filteredFinalStatusOptions} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              label="Slip No."
              name="slipNo"
              rules={[
                { required: true, message: "Please input slip number!" },
              ]}>
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Remarks" name="remarks">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Table
          dataSource={rows}
          columns={[
            {
              title: "SKU Code",
              dataIndex: "skuCode",
              width: 180,
              render: (_, record, index) => (
                <div style={{ padding: "0px" }}>
                  <Select
                    styles={{ padding: "inherit" }}
                    value={{
                      label: record.skuCode,
                      value: record.skuCode,
                    }}
                    options={stockItems.map((item) => ({
                      value: item.code,
                      label: item.code,
                    }))}
                    onChange={(selectedOption) =>
                      handleSKUChange(selectedOption, index)
                    }
                  />
                </div>
              ),
            },
            {
              title: "Item Name",
              dataIndex: "itemName",
              width: 170,
              render: (_, record, index) => (
                <Select
                  value={{
                    label: record.itemName,
                    value: record.itemName,
                  }}
                  options={itemOptions}
                  onChange={(selectedOption) =>
                    handleItemNameChange(selectedOption, index)
                  }
                />
              ),
            },
            {
              title: "Description",
              dataIndex: "description",
              width: 230,
              render: (_, record, index) => (
                <Select
                  options={record.descOptions || []}
                  value={{
                    label: record.description,
                    value: record.description,
                  }}
                  onChange={(selectedOption) =>
                    handleDescriptionChange(selectedOption, index)
                  }
                />
              ),
            },
            {
              title: "Unit",
              dataIndex: "unit",
              render: (_, record) => <Input value={record.unit} disabled />,
            },
            {
              title: "Rate",
              dataIndex: "rate",
              width: 140,
              render: (_, record, index) => (
                <Select
                  options={record.rateOptions || []}
                  value={{
                    label: record.rate || "",
                    value: record.rate || "",
                  }}
                  onChange={(selectedOption) =>
                    handleRateChange(selectedOption, index)
                  }
                />
              ),
            },
            {
              title: "Rack No.",
              dataIndex: "rackNo",
              width: 140,
              render: (_, record, index) => (
                <Select
                  options={racklist || []}
                  value={{
                    label: record.rackNo || "",
                    value: record.rackNo || "",
                  }}
                  onChange={(selectedOption) =>
                    handleRackChange(selectedOption, index)
                  }
                />
              ),
            },
            {
              title: "Quantity",
              dataIndex: "quantity",
              width: 100,
              render: (_, record, index) => (
                <Input
                  type="number"
                  step="0.001"
                  value={record.quantity}
                  onChange={(e) => handleQuantityChange(e, index)}
                />
              ),
            },
            {
              title: "Balance",
              dataIndex: "balance",
              render: (_, record) => <Input value={record.balance} disabled />,
            },
            {
              title: "Action",
              dataIndex: "action",
              width: 140,
              render: (_, record) => (
                <>
                  <Button
                    type="link"
                    onClick={() => handleDeleteRow(record.key)}>
                    <DeleteOutlined />
                  </Button>
                  <Button
                    type="link"
                    onClick={handleAddRow}
                    style={{ marginLeft: 1 }}>
                    <PlusOutlined />
                  </Button>
                </>
              ),
            },
          ]}
          pagination={false}
          rowKey="key"
          rowClassName={() => "compact-row"}
          style={{ padding: 0 }}
        />
        <br />
        <Form.Item>
          <center>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </center>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default IssueItemFormModal;
