import React, { useEffect, useState } from "react";
import {
  Table,
  Checkbox,
  Input,
  Button,
  Popconfirm,
  Typography,
  Form,
  Tag,
  message,
  Modal,
  DatePicker,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { GetDealerIntendtData } from "../../redux/actions/salesAction";
import { SetModelId } from "../../redux/actions/modalAction";
import EditableCell from "../../components/Common/editablecell";
import "../../components/Common/style/button.css";
import config from "../../config";
import { api } from "../../services/api";
import dayjs from "dayjs";
import DealerForm from "./Form/dealerinform";

const { Option } = Select;
const Dealer_Intend = () => {
  const Column_Col = [
    {
      title: "Operation",
      dataIndex: "edit",
      render: (_, record) => {
        const editable = isEditing(record);
        const currentDate = new Date();
        const recordDate = new Date(record.created_on); // Assuming there's a 'date' property in the record object

        // Calculate the difference in days
        const diffInDays = Math.floor(
          (currentDate - recordDate) / (1000 * 60 * 60 * 24)
        );
        console.log(diffInDays);

        if (diffInDays < 1) {
          // If 1 day has passed, give all users access
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record.id)}
                style={{
                  marginRight: 8,
                }}>
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link
              style={{ color: "blue" }}
              disabled={editingKey !== ""}
              onClick={() => edit(record)}>
              <Tag bordered={false} color="blue">
                Edit
              </Tag>
            </Typography.Link>
          );
        } else if (diffInDays <= 10) {
          const user = localStorage.getItem("username"); // Assuming 'user' is the key for storing the user type in localStorage
          if (user === "admin" || user === "EMP091") {
            // If the user is 'admin' or 'emp001', provide access
            return editable ? (
              <span>
                <Typography.Link
                  onClick={() => save(record.id)}
                  style={{
                    marginRight: 8,
                  }}>
                  Save
                </Typography.Link>
                <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                  <a>Cancel</a>
                </Popconfirm>
              </span>
            ) : (
              <Typography.Link
                style={{ color: "blue" }}
                disabled={editingKey !== ""}
                onClick={() => edit(record)}>
                <Tag bordered={false} color="blue">
                  Edit
                </Tag>
              </Typography.Link>
            );
          }
        } else {
          return null;
        }
      },
      width: 100,
    },
    {
      title: "Date",
      dataIndex: "created_on",
      render: (created_on) => {
        const date = new Date(created_on);
        const formattedDate = `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()}`;
        return formattedDate;
      },
      sorter: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: true,
    },
    {
      title: "Firm Name",
      dataIndex: "firmname",
      key: "firmname",
      editable: true,
      align: "center",
      ellipsis: true,
    },
    {
      title: "Phone No.",
      dataIndex: "phoneno",
      key: "phoneno",
      align: "center",
      ellipsis: true,
      editable: true, // Mark this column as editable
      render: (text) => (text ? text.toString() : ""), // To ensure consistent rendering
      onCell: (record) => ({
        record,
        dataIndex: "phoneno",
        title: "Phone No.",
        editable: true,
        inputType: "number",
        rules: [
          {
            required: true,
            message: "Please enter a 10-digit phone number",
          },
          {
            pattern: /^\d{10}$/,
            message: "Phone number must be 10 digits",
          },
        ],
      }),
    },
    {
      title: "Rate",
      dataIndex: "rate",
      ellipsis: true,
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "number",
        dataIndex: "rate",
        title: "Rate",
        editing: isEditing(record),
      }),
    },
    {
      title: "Shop Size in Sq. Ft.",
      dataIndex: "shopsize",
      editable: true,
      ellipsis: true,
    },
    {
      title: "Decision makers",
      dataIndex: "decision",
      autoFitWidth: true, // Enable auto fittin
      width: 150, // Set a fixed width for this column
      render: (text, record, index) => {
        const isEditing = editingKey === record.id; // Check if the current row is in edit mode
        return isEditing ? (
          <EditableCell
            editing={isEditing}
            dataIndex="decision"
            title="Decision makers"
            inputType="select"
            record={record}
            index={index}
            selectOptions={options.decisionMaker}>
            {text}
          </EditableCell>
        ) : (
          <span>{text}</span>
        );
      },
    },
    {
      title: "Shop",
      dataIndex: "shop",
      autoFitWidth: true, // Enable auto fittin
      render: (text, record, index) => {
        const isEditing = editingKey === record.id; // Check if the current row is in edit mode
        return isEditing ? (
          <EditableCell
            editing={isEditing}
            dataIndex="shop"
            title="Shop"
            inputType="select"
            record={record}
            index={index}
            selectOptions={options.shopType}>
            {text}
          </EditableCell>
        ) : (
          <span>{text}</span>
        );
      },
    },
    {
      title: "No. of Employee Present",
      dataIndex: "employee",
      editable: true,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      ellipsis: true,
      render: (image) => {
        if (image) {
          return (
            <a href={image} target="_blank" rel="noopener noreferrer">
              <img
                src={image} // Assuming the image is a URL or base64-encoded string
                alt="thumbnail"
                style={{
                  width: "50px",
                  height: "50px",
                  objectFit: "cover",
                  borderRadius: "4px",
                }}
              />
            </a>
          );
        }
        return "No Image";
      },
    },
  ];
  const expandedColumns = [
    {
      title: "What is the type of uses?",
      dataIndex: "types",
      onCell: (record) => ({
        record,
        inputType: "select", // Change inputType to "select"
        dataIndex: "types",
        title: "What is the type of uses?",
        editing: isEditing(record),
        selectOptions: [
          { label: "Project", value: "Project" },
          { label: "End Use", value: "End Use" },
          { label: "Trading or Reselling", value: "Trading or Reselling" },
        ], // Add select options with non-null values
      }),
    },
    {
      title: "What is the Grade of Percent (%) pipe you require?",
      dataIndex: "grade",
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: "grade",
        title: "What is the Grade of Percent (%) pipe you require?(304/JT)",
        editing: isEditing(record),
      }),
    },
    {
      title: "What is the quantity of tube or pipe you require?",
      dataIndex: "quantity",
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "select",
        dataIndex: "quantity",
        title: "What is the quantity of tube or pipe you require?",
        editing: isEditing(record),
        selectOptions: [
          { value: "5MT", label: "5MT" },
          { value: "10MT", label: "10MT" },
          { value: "15MT", label: "15MT" },
          { value: "20MT", label: "20MT" },
          { value: "25MT or above", label: "25MT or above" },
        ],
      }),
    },
    {
      title: "What is the delivery address and preferred delivery date?",
      dataIndex: "address",
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: "address",
        title: "What is the delivery address and preferred delivery date?",
        editing: isEditing(record),
      }),
    },
    {
      title: "What is the size and dimensions of the tube or pipe you require?",
      dataIndex: "size",
      onCell: (record) => ({
        record,
        inputType: "number",
        dataIndex: "size",
        title:
          "What is the size and dimensions of the tube or pipe you require?",
        editing: isEditing(record),
      }),
    },
    {
      title: "Current Brands Dealing In?",
      dataIndex: "brand",
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: "brand",
        title: "Current Brands Dealing In?",
        editing: isEditing(record),
      }),
    },
    {
      title: "Type of Requirement?",
      dataIndex: "requirement",
      onCell: (record) => ({
        record,
        inputType: "select",
        dataIndex: "requirement",
        title: "Type of Requirement?",
        editing: isEditing(record),
        selectOptions: [
          { value: "On Time", label: "On Time" },
          { value: "Recurring", label: "Recurring" },
        ],
      }),
    },
  ];

  const options = {
    shopType: [
      { value: "Owned", label: "Owned" },
      { value: "Rented", label: "Rented" },
    ],
    decisionMaker: [
      { value: "Owned", label: "Owned" },
      { value: "Son", label: "Son" },
      { value: "Father", label: "Father" },
      { value: "Partnership", label: "Partnership" },
      { value: "Brothers", label: "Brothers" },
      { value: "Other", label: "Other" },
    ],
  };

  const expandedRowRender = (record) => (
    <Table
      columns={expandedColumns}
      dataSource={record.expandedData || []}
      pagination={false}
      size="small"
      rowKey={(record) => record.id}
      components={{
        body: {
          cell: EditableCell,
        },
      }}
      bordered
      responsive={{
        xs: "stack",
        sm: "stack",
        md: "stack",
        lg: "stack",
        xl: "stack",
        xxl: "stack",
      }}
    />
  );

  const DealerIntend = useSelector((state) => state.SalesData.DealerIntendData);

  if (DealerIntend) {
    var count = DealerIntend.count;
    var Dealerintendform = DealerIntend.results;
  }

  const filtCols = {
    id: [],
    code: "",
    company: "",
    status: "",
    customer: "",
    phone: "",
    created_on: {
      start: "",
      end: "",
    },
  }; //filter
  const [filter, setFilter] = useState(() => {
    const storedFilter = localStorage.getItem("currentFilter");
    return storedFilter ? JSON.parse(storedFilter) : filtCols;
  });
  const [refresh, setRef] = useState(false);
  const [columns, setColumns] = useState([]);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [size, setSize] = React.useState(10);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.id === editingKey;
  const [counts, setCount] = useState(0);
  const [page, setPage] = useState(() => {
    const storedPage = localStorage.getItem("currentPage");
    return storedPage ? parseInt(storedPage) : 1;
  });
  const dispatch = useDispatch();

  const edit = (record) => {
    form.setFieldsValue({
      coil_no: "",
      company: "",
      grade: "",
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  useEffect(() => {
    dispatch(
      GetDealerIntendtData({
        size: size,
        page: page,
        filter: filter,
      })
    );
  }, [page, filter, size, refresh]);

  useEffect(() => {
    const storedFilter = localStorage.getItem("dealerintendFilter");
    const storedPage = localStorage.getItem("dealerintendPage");
    if (storedFilter) {
      setFilter(JSON.parse(storedFilter));
    }
    if (storedPage) {
      setPage(parseInt(storedPage));
    }
  }, []);

  useEffect(() => {
    // Update local storage whenever count changes
    localStorage.setItem("counts", counts.toString());
  }, []);

  useEffect(() => {
    localStorage.setItem("dealerintendFilter", JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    localStorage.setItem("dealerintendPage", page.toString());
  }, [page]);

  function handlePageChange(pg) {
    setPage(pg);
  }

  const handleFilterdateChange = (selectedKeys, dataIndex) => {
    const updatedFilter = {
      ...filter,
      [dataIndex]: {
        start: selectedKeys[0] || null,
        end: selectedKeys[1] || null,
      },
    };
    setFilter(updatedFilter);
  };

  const handlePageSizeChange = (current, pageSize) => {
    const totalPage = Math.ceil(count / pageSize); // Calculate the total number of pages
    const currentPage = Math.min(page, totalPage); // Get the current page, ensuring it is not greater than the total page count
    setSize(pageSize);
    setPage(currentPage); // Set the page to the current page after changing the page size
  };

  function CloseModal() {
    console.log("yyy");
    dispatch(SetModelId(0));
    setRef(!refresh);
  }

  const handleFilterChange = (columnKey, value) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [columnKey]: value,
    }));
  };

  const handleColumnFilter = (selectedKeys, confirm, dataIndex) => {
    confirm();
    if (selectedKeys.length === 0) {
      handleFilterChange(dataIndex, ""); // Set filter value to an empty string
    } else {
      handleFilterChange(dataIndex, selectedKeys[0]);
    }
  };
  const handleClearFilters = () => {
    setFilter({ ...filtCols });
    setRef((prevRef) => !prevRef);
    resetAllFilters();
    CloseModal();
  };

  const getColumnSearchProps = (dataIndex) => {
    if (dataIndex === "created_on") {
      return {
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => {
          return (
            <div style={{ padding: 8 }}>
              <DatePicker.RangePicker
                value={
                  selectedKeys.length === 2
                    ? selectedKeys
                    : [
                        filter[dataIndex]?.start
                          ? dayjs(filter[dataIndex].start, "DD-MM-YYYY")
                          : null,
                        filter[dataIndex]?.end
                          ? dayjs(filter[dataIndex].end, "DD-MM-YYYY")
                          : null,
                      ]
                }
                onChange={(dates) =>
                  handleFilterdateChange(
                    dates.map((date) =>
                      date ? date.format("DD-MM-YYYY") : null
                    ),
                    dataIndex
                  )
                }
                onOk={confirm}
                style={{ marginBottom: 8, display: "block" }}
              />
              <Button
                type="primary"
                onClick={confirm}
                size="small"
                style={{ width: "30%", marginRight: 8 }}>
                Filter
              </Button>
              <Button onClick={handleClearFilters} size="small" role="button">
                <i className="fa fa-refresh me-1">Reset Filters</i>
              </Button>
            </div>
          );
        },
        // ...
      };
    } else {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleColumnFilter(selectedKeys, confirm, dataIndex)
              }
              style={{ width: "100%", marginBottom: 8, display: "block" }}
            />
            <Button
              type="primary"
              onClick={() =>
                handleColumnFilter(selectedKeys, confirm, dataIndex)
              }
              size="small"
              style={{ width: "40%", marginRight: 8 }}>
              Filter
            </Button>
            <Button onClick={handleClearFilters} size="small" role="button">
              <i className="fa fa-refresh me-1">Reset Filters</i>
            </Button>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            ?.toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
      };
    }
  };

  const columnsWithFilter = Column_Col.map((column) => {
    if (column.dataIndex && column.editable) {
      // Add a check for editable columns
      return {
        ...column,
        ...getColumnSearchProps(column.dataIndex),
        onCell: (record) => ({
          record,
          inputType:
            column.dataIndex === "rate" ||
            column.dataIndex === "shopsize" ||
            column.dataIndex === "employee" ||
            column.dataIndex === "phoneno"
              ? "number"
              : "text",
          dataIndex: column.dataIndex,
          title: column.title,
          editing: isEditing(record),
        }),
      };
    }
    if (column.dataIndex === "firmname") {
      return {
        ...column,
        ...getColumnSearchProps(column.dataIndex),
      };
    } else if (column.dataIndex === "created_on") {
      return {
        ...column,
        ...getColumnSearchProps(column.dataIndex),
      };
    }
    return column;
  });

  const updateData = (newData) => {
    setData(newData);
  };
  const save = async (key) => {
    console.log(key);
    try {
      const row = await form.validateFields();
      updateData((prevData) => {
        const newData = [...prevData];
        const index = newData.findIndex((item) => key === item.key);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...row,
          });
        } else {
          newData.push(row);
        }
        return newData;
      });
      setEditingKey("");
      api({
        api: "/api/dealerintend/",
        method: "post",
        body: {
          common: row,
          post: 2,
          id: key,
        },
      });
      setData((prevData) => [...prevData, row]);
      setTimeout(() => {
        console.log("Data saved successfully!");
        message.success("Data saved successfully!");
        CloseModal();
      }, 1000);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
      message.success("Validate Failed:", errInfo);
    }
  };

  const resetAllFilters = () => {
    const updatedColumns = columnsWithFilter.map((column) => {
      delete column.filteredValue; // Remove filteredValue from column
      if (column.filterDropdown) {
        column.filterDropdown.visible = false; // Hide filter dropdown if visible
      }
      return column;
    });
    setColumns(updatedColumns);
  };
  const [modalVisible, setModalVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleExportClick = () => {
    setModalVisible(true);
  };

  function export_data() {
    var from = startDate.format("YYYY-MM-DD");
    var to = endDate.format("YYYY-MM-DD");
    const key = localStorage.getItem("AuthToken");
    let url =
      config.apiEndpoint +
      "/export_polish/?from=" +
      from +
      "&to=" +
      to +
      "&token=" +
      key;
    window.open(url, "_blank");
  }

  let dealerintendExpandedData = [];
  if (Dealerintendform) {
    dealerintendExpandedData = Dealerintendform.map((record) => ({
      ...record,
      expandedData: [
        // Populate the expandedData array with the corresponding data
        {
          types: record.types,
          grade: record.grade,
          quantity: record.quantity,
          address: record.address,
          size: record.size,
          brand: record.brand,
          requirement: record.requirement,
          shop: record.shop,
          employee: record.employee,
          shopsize: record.shopsize,
          decision: record.decision,
          consumption304: record.consumption304,
          consumptionjt: record.consumptionjt,
          id: record.id,
        },
        // Add more objects for additional rows in the expanded table
      ],
    }));
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      className="border  table_body"
      style={{ overflowX: "auto", maxWidth: "100%", width: "100%" }}>
      <div className="table-container">
        <Typography.Title style={{ color: "blue" }} level={5}>
          Dealer Intend
        </Typography.Title>
        <div className="ms-auto filter-component">
          <Button
            type="primary"
            style={{ marginBottom: "30px" }}
            onClick={showModal}>
            Add Item
          </Button>
          <Modal
            title="Dealer Information"
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            width={800}>
            <DealerForm onClose={handleCancel} />
          </Modal>
        </div>
        {/* <Polish_Form refreshData={refreshData} /> */}
        {/* <PolishManpower_Form /> */}
        <Form form={form} component={false}>
          {Dealerintendform && (
            <Table
              columns={columnsWithFilter}
              dataSource={dealerintendExpandedData}
              expandable={{ expandedRowRender }}
              rowClassName="editable-row"
              pagination={{
                current: page,
                pageSize: size,
                total: count,
                onChange: handlePageChange,
                onShowSizeChange: handlePageSizeChange,
                showSizeChanger: true,
                pageSizeOptions: [
                  "10",
                  "15",
                  "20",
                  "25",
                  "30",
                  "50",
                  "70",
                  "100",
                ],
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
              rowKey={(record) => record.id}
              size="small"
              bordered
              loading={Dealerintendform === null}
              onChange={(pagination, filters, sorter) => {
                setFilter(filters);
              }}
              responsive={{
                xs: "stack",
                sm: "stack",
                md: "stack",
                lg: "stack",
                xl: "stack",
                xxl: "stack",
              }}
              scroll={{ x: true }}
              mobileBreakPoint={768}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
            />
          )}
        </Form>
        <Modal
          title="Download"
          open={modalVisible}
          onOk={export_data}
          onCancel={() => setModalVisible(false)}>
          <DatePicker.RangePicker
            value={[startDate, endDate]}
            onChange={(dates) => {
              setStartDate(dates[0]);
              setEndDate(dates[1]);
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default Dealer_Intend;
