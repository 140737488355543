import { useState } from "react";
import { Input, InputNumber, Form, Select } from "antd";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  selectOptions = [],
  children, // Include 'children' here
  ...restProps
}) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleSelectOpen = () => setIsSelectOpen(true);
  const handleSelectClose = () => setIsSelectOpen(false);

  const inputNode =
    inputType === "number" ? (
      <InputNumber style={{ width: "100%" }} />
    ) : inputType === "select" ? (
      <Select
        showSearch
        onBlur={handleSelectClose}
        onFocus={handleSelectOpen}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={{ width: "100%" }}
        dropdownMatchSelectWidth={false} // Ensures dropdown width adjusts based on content
        dropdownStyle={{ maxHeight: 400, overflowY: "auto" }} // Adds a scroll bar if options exceed max height
      >
        {selectOptions.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    ) : (
      <Input style={{ width: "100%" }} />
    );

  const value = dataIndex ? record[dataIndex] : "";

  return (
    <td
      {...restProps}
      style={{
        position: "relative",
        width: "auto",
        maxWidth: "150px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0, width: "100%" }}
          initialValue={value}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}>
          {inputNode}
        </Form.Item>
      ) : (
        children // Use 'children' for non-editing state
      )}
    </td>
  );
};

export default EditableCell;
